
  /* ==========================================================================
     Helper classes
     ========================================================================== */

  /*
    Hide visually and from screen readers
   */

   .hidden,
   [hidden] {
     display: none !important;
   }

   /*
     Hide only visually, but have it available for screen readers:
     https://snook.ca/archives/html_and_css/hiding-content-for-accessibility

     1. For long content, line feeds are not interpreted as spaces and small width
        causes content to wrap 1 word per line:
        https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
    */

   .sr-only {
     border: 0;
     clip: rect(0, 0, 0, 0);
     height: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
     white-space: nowrap;
     width: 1px;
     /* 1 */
   }

   /*
     Extends the .sr-only class to allow the element
     to be focusable when navigated to via the keyboard:
     https://www.drupal.org/node/897638
    */

   .sr-only.focusable:active,
   .sr-only.focusable:focus {
     clip: auto;
     height: auto;
     margin: 0;
     overflow: visible;
     position: static;
     white-space: inherit;
     width: auto;
   }

   /*
    Hide visually and from screen readers, but maintain layout
    */

   .invisible {
     visibility: hidden;
   }

   /*
     Clearfix: contain floats

     For modern browsers
     1. The space content is one way to avoid an Opera bug when the
        `contenteditable` attribute is included anywhere else in the document.
        Otherwise it causes space to appear at the top and bottom of elements
        that receive the `clearfix` class.
     2. The use of `table` rather than `block` is only necessary if using
        `:before` to contain the top-margins of child elements.
    */

   .clearfix::before,
   .clearfix::after {
     content: " ";
     display: table;
   }

   .clearfix::after {
     clear: both;
   }
