/* ==========================================================================
     EXAMPLE Media Queries for Responsive Design.
     These examples override the primary ('mobile first') styles.
     Modify as content requires.
     ========================================================================== */
@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(.8) contrast(1.2);
  }
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  body {
    transition: none;
  }
}

@media screen and (-ms-high-contrast: active) {
  button.btn {
    border: 2px solid currentcolor;
  }
}


@media only screen and (min-width: 530px) {
  // header


  // projects
  .project-list__item-desc {
    max-width: 30ch;
    text-align: left;
  }

  //  skills
  .skills-list li {
    flex-basis: 25%;
  }
}

@media only screen and (min-width: 760px) {

  // header
  .nav {
    min-height: 10vh;
    align-self: flex-end;
    flex-direction: row;
  }

  header .menu-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  header #toggle {
    display: none;
  }


  // projects
  article.project-list__item:nth-child(1)>div:nth-child(1),
  article.project-list__item:nth-child(3)>div:nth-child(1) {
    flex-direction: row;
  }

  article.project-list__item:nth-child(2)>div:nth-child(1),
  article.project-list__item:nth-child(4)>div:nth-child(1) {
    flex-direction: row-reverse;
  }

  .projects-list .react img,
  .projects-list .unplugged img,
  .projects-list .statventures img,
  .projects-list .halloween img {
    max-width: 75%;
    flex-basis: 75%;
  }

  .project-list__item.react {
    margin-top: 0;
  }

  // about
  .about-info {
    flex-direction: row
  }

  .about figure {
    flex-basis: 33%;
    align-self: flex-start;
    margin: 0 2rem;
  }

  .about-info__text {
    flex-basis: 66%;
    max-width: 60ch;
    margin: 0 2rem;

    p:first-child {
      margin-top: 0;
    }
  }

  // contact
  .form {
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  .form-inputs,
  .form-text {
    flex-basis: 50%;
  }

  .form-text p,
  .form-inputs ::placeholder {
    text-align: left;
    font-family: $primary-font;
  }

  .form-text {
    margin-left: 30px;
  }

}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}
