body.light {
  --text: #0d0d0d;
  --background: #fafafa;
}
body.dark {
  --text: #eff0f3;
  --background: #0d0d0d;
}

body {
  color: var(--text);
  background: var(--background);
  transition: ease 0.5s;
}

button.btn,
a.btn {
  border: 2px var(--text) solid;
}

button.menu-btn {
  border: none;
}

.down-arrow-link {
  color: var(--text);
}

a:visited {
  color: var(--text);
}
