@import "./resets";
/* ==========================================================================
Author's custom styles
========================================================================== */

// Global Styles
$primary-font: 'Montserrat', sans-serif;
$accent-font: 'Aclonica', sans-serif;
$black: #0d0d0d;
$white: #eff0f3;
$outline: lawngreen;

h1,
h2,
h3,
h4,
p,
button {
  margin-block-start: 0;
  margin-block-end: 0;
}


h1,
h2,
h3,
a,
button,
nav {
  font-family: $accent-font;
  font-variant: small-caps;
  letter-spacing: 0.07rem;
}

// h2,
// h3,
h4,
p {
  font-family: $primary-font;
  line-height: 1.7;
}

h1 {
  font-size: 2.75rem;

  :first-child {
    border-bottom: 3px $outline solid;
  }
}

h2 {
  font-size: 2rem;
  font-variant: all-petite-caps;
  border-bottom: 3px darken($outline, 15%) solid;
  margin-top: 20vh;
}

ul li {
  list-style-type: none;
  padding: 1rem;
}

// basic layout
@mixin flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

@mixin listSetting {
  text-decoration: none;
  list-style-type: none;
  color: inherit;
}

body {
  height: 100%;
}

.container {
  width: 100%;
  min-height: 100%;
  margin: auto;
  border: $outline 5px solid;
  // ! change to animated (?) gradient

  * {
    text-align: center;
  }
}

//! universal selector inbound!
.container>* {
  width: 100%;
}

.container,
nav,
.menu-nav,
.wrapper,
.projects-list,
.project-list__item,
.project-list__name,
.about-info {
  @include flexCenter();
}

.wrapper {
  width: min(95%, 1180px);
  margin: auto;
}

button.btn {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: inherit;
}

button.btn,
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // border: 2px inherit solid;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  padding: 0.25em 0.75em;
  min-width: 10ch;
  min-height: 44px;
  text-align: center;
  line-height: 1.1;
  transition: 220ms all ease-in-out;

  &:hover,
  &:active {
    color: scale-color($outline, $lightness: -20%);
    border: scale-color($outline, $lightness: -20%) solid 2px;
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 2px scale-color($outline, $lightness: -40%);
    border: 2px solid var(--background);
    color: scale-color($outline, $lightness: -40%);
  }

}

// header
header {
  .menu-btn {
    font-size: 1rem;
    color: inherit;
    display: none;
    box-shadow: none;
  }

  .menu-nav {
    flex-direction: column;

    a {
      @include listSetting();
      padding: 0.25em 0.75em;
      min-width: 10ch;
      min-height: 44px;
      transition: 220ms all ease-in-out;

      &:hover,
      &:active {
        color: scale-color($outline, $lightness: -20%);
        border-bottom: scale-color($outline, $lightness: -20%) solid 2px;
      }

      &:focus {
        outline-style: solid;
        outline-color: transparent;
        box-shadow: 0 0 0 2px scale-color($outline, $lightness: -40%);
        border: 2px solid var(--background);
        color: scale-color($outline, $lightness: -40%);
      }
    }
  }

  .home {
    min-height: 90vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
  }

  .home.wrapper {
    justify-content: flex-start;
  }

}

header .menu-nav {
  display: none;
}

header .menu-btn {
  display: block;
}

header .menu-nav.is-active {
  display: block;
}

.theme-toggle {
  border: none;
  // outline: none;
  // outline needs a value
  padding: 20px;
  // border-radius: 50%;
  cursor: pointer;
  background: inherit;
  color: inherit;
}

.theme-toggle i {
  font-size: 1.5rem;
}

.home-info h1 {
  text-align: left;
}

.down-arrow-link {
  min-width: 10ch;
  min-height: 44px;
  transition: 220ms all ease-in-out;
  position: relative;
  top: -70px;

  &:hover,
  &:active {
    color: $outline;
    transform: scale(1.1);
  }
}

header i {
  text-align: center;
  font-size: 2rem;
}

header .bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

// project
i.devicon-sketch-line {
  font-weight: bolder;
}

.project-list__item {
  margin: 3rem 2rem;
}


.project-list__item-info {
  margin: 2rem 0rem;
  width: 100%;
  // border: blue solid 1px;
  flex-basis: 50%;
  padding: 2rem;
  @include flexCenter();

  >* {
    margin: 1.5rem 0rem;
  }
}

.projects-list .react img,
.projects-list .unplugged img,
.projects-list .statventures img,
.projects-list .halloween img {
  width: 100%;
}

.projects-list .react img {
  aspect-ratio: 622/401;
}

.projects-list .unplugged img {
  aspect-ratio: 319/181;
}

.projects-list .statventures img {
  aspect-ratio: 1920/995;
}

.projects-list .halloween img {
  aspect-ratio: 512/289;
}

.project-list__item-skills-icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  * i {
    font-size: 3rem;
  }
}

.project-list__item-links a {
  // display: inline-block;
  // padding: 25px;
  // margin: 10px;
  // outline: 1px solid olive;
  @include listSetting ();

}


// skills
.skills {
  // margin: 3rem 2rem;
}

.skills-list {
  margin: 3rem 0;
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  font-variant: small-caps;

  li {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;

    i {
      font-size: 3rem;
      transition: 220ms all ease-in-out;

      &:hover {
        color: $outline;
        transform: scale(1.1);
      }
    }
  }
}

li.skills-item:nth-child(6)>p:nth-child(2) {
  max-width: min-content;
}

// about
.about {
  margin: 3rem 2rem;
}

.about-info {
  margin: 3rem 0;
  width: fit-content;
}

.about figure {
  max-width: 300px;
}

.about img {
  aspect-ratio: 1/1;
  width: 100%;
}

.about p {
  margin: 25px 0;
  text-align: left;
}

// contact
.contact {
  margin: 3rem 2rem;
}

.contact .wrapper {
  @include flexCenter();
}

.form {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;

  a {
    @include listSetting();
  }

  i {
    font-size: 2rem;
  }
}

.form input {
  max-height: 40px;
  margin-bottom: 15px;
}

.form input,
.form textarea {
  display: block;
  min-width: 100%;
  padding: 10px;

  :focus{
    border-color: none;
  }
}

.form-inputs :focus-visible {
  text-align: left;
  outline: solid 2px scale-color($outline, $lightness: -40%);
  border-color: transparent;
}

.form button {
  margin-top: 10px;
}

.form-text__sm-list {
  display: flex;
  justify-content: center;
}

.form-text__sm-link i {
  font-size: 2.5rem;
  transition: 220ms all ease-in-out;

  &:hover,
  &:active {
    color: $outline;
    transform: scale(1.1);
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 2px scale-color($outline, $lightness: -40%);
  }
}

// rest of imports
@import"./themes";
@import "./helperclass";
@import "./mediaqueries";
@import "./printstyles";
